<template>
  <div class="app-list">
    <div class="flex jc-end mb-4">
      <a-button type="primary" @click="showTaskList" v-if="formData.ratingOpened">评分明细</a-button>
    </div>
    <div class="mb-6 scratch-card-container" v-if="formData.id">
      <h1 style="text-align: center">等级刮刮乐</h1>
      <scratch-card
        :showResult="formData.ratingOpened"
        coverText="刮奖区"
        :ratio="0.3"
        :move-radius="20"
        :clearCallback="cardOpen"
      >
        <template slot="result">
          <div class="result">
            {{ formData.level == 1 ? '高级' : formData.level == 2 ? '中级' : '喜提低级荣誉称号，加油奥利给！' }}
          </div>
        </template>
      </scratch-card>
    </div>
    <div v-if="showScoreDetail">
      <div class="">
        <g-title>基本信息</g-title>
        <info-row label="月份">{{ formData.month }}</info-row>
        <info-row label="人员">{{ formData.nickName }}</info-row>
        <info-row label="职位">{{ formData.positionName }}</info-row>
        <info-row label="作品数量">{{ formData.productionCount }}</info-row>
        <info-row label="任务数量">{{ formData.taskCount }}</info-row>
        <info-row label="平均分">{{ formData.averageScore }}</info-row>
        <info-row label="等级">{{ formData.level == 1 ? '高级' : formData.level == 2 ? '中级' : '低级' }}</info-row>
        <info-row label="备注" wrapCol="19">
          <a-textarea autoSize v-model="formData.comment"></a-textarea>
        </info-row>
        <div style="text-align: right" v-if="formData.status != 4">
          <a-button type="danger" class="mr-3" @click="rejectRating" v-if="formData.status > 1">驳回</a-button>
          <a-button type="primary" @click="confirmRating">确认</a-button>
        </div>
      </div>
      <g-title>评分明细</g-title>
      <a-table
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="taskList"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 800, y: 500 }"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import { getAction, postAction, putAction } from '@/api/manage'
import ScratchCard from '@/components/tools/scratch-card.vue'

export default {
  name: 'RatingPage',
  components: {
    ScratchCard,
  },
  data() {
    return {
      showScoreDetail: false,
      url: {
        edit: '/rating/edit',
      },
      formData: {},
      taskList: [],
      loading: false,
      columns: [
        {
          title: '章节序号',
          dataIndex: 'chapterSequenceNo',
          width: 100,
        },
        {
          title: '数量',
          dataIndex: 'pageCount',
          width: 100,
        },
        {
          title: '人员',
          dataIndex: 'user.userName',
          width: 100,
        },
        {
          title: '截止日期',
          dataIndex: 'deadline',
          width: 120,
        },
        {
          title: '结算日期',
          dataIndex: 'settlementDate',
          width: 120,
        },
        {
          title: '考核结果',
          dataIndex: 'evalResult',
          width: 200,
        },
        {
          title: '考核得分',
          dataIndex: 'evalScore',
        },
      ],
    }
  },

  created() {
    this.getMyRating()
  },
  methods: {
    getMyRating() {
      getAction('/rating/my').then(({ success, data }) => {
        if (success) {
          this.formData = data
        }
      })
    },
    showTaskList() {
      this.showScoreDetail = !this.showScoreDetail
      if (this.showScoreDetail && this.taskList.length == 0) {
        this.loading = true
        getAction('/rating/get_tasks?ids=' + this.formData.taskIds).then(({ success, data }) => {
          if (success) {
            this.taskList = data
          }
          this.loading = false
        })
      }
    },

    confirmRating() {
      postAction('/rating/confirm', { id: this.formData.id, pass: true, comment: this.formData.comment }).then(
        ({ success, message,msg }) => {
          if (!success) {
            this.$message.error(message||msg)
          } else {
            this.$message.success('确认成功')
            this.getMyRating()
          }
        }
      )
    },

    rejectRating() {
      postAction('/rating/confirm', { id: this.formData.id, pass: false, comment: this.formData.comment }).then(
        ({ success, message,msg }) => {
          if (!success) {
            this.$message.error(message||msg)
          } else {
            this.$message.success('驳回成功')
          }
        }
      )
    },

    cardOpen() {
      putAction('/rating/open?id=' + this.formData.id).then(({ success, message,msg }) => {
        if (!success) {
          this.$message.error(message||msg)
        } else {
          this.formData.ratingOpened = true
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.data {
  background: rgb(221, 79, 64);
  color: white;
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  align-items: center;
  text-align: center;
}
.scratch-card-container {
  height: 160px;
  width: 400px;
  background: #ccc;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.app-list {
  .meta-cardInfo {
    zoom: 1;
    margin-top: 16px;

    > div {
      position: relative;
      text-align: left;
      float: left;
      width: 50%;

      p {
        line-height: 32px;
        font-size: 24px;
        margin: 0;

        &:first-child {
          color: rgba(0, 0, 0, 0.45);
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>