var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-list" }, [
    _c(
      "div",
      { staticClass: "flex jc-end mb-4" },
      [
        _vm.formData.ratingOpened
          ? _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.showTaskList } },
              [_vm._v("评分明细")]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.formData.id
      ? _c(
          "div",
          { staticClass: "mb-6 scratch-card-container" },
          [
            _c("h1", { staticStyle: { "text-align": "center" } }, [
              _vm._v("等级刮刮乐"),
            ]),
            _c(
              "scratch-card",
              {
                attrs: {
                  showResult: _vm.formData.ratingOpened,
                  coverText: "刮奖区",
                  ratio: 0.3,
                  "move-radius": 20,
                  clearCallback: _vm.cardOpen,
                },
              },
              [
                _c("template", { slot: "result" }, [
                  _c("div", { staticClass: "result" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.formData.level == 1
                            ? "高级"
                            : _vm.formData.level == 2
                            ? "中级"
                            : "喜提低级荣誉称号，加油奥利给！"
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.showScoreDetail
      ? _c(
          "div",
          [
            _c(
              "div",
              {},
              [
                _c("g-title", [_vm._v("基本信息")]),
                _c("info-row", { attrs: { label: "月份" } }, [
                  _vm._v(_vm._s(_vm.formData.month)),
                ]),
                _c("info-row", { attrs: { label: "人员" } }, [
                  _vm._v(_vm._s(_vm.formData.nickName)),
                ]),
                _c("info-row", { attrs: { label: "职位" } }, [
                  _vm._v(_vm._s(_vm.formData.positionName)),
                ]),
                _c("info-row", { attrs: { label: "作品数量" } }, [
                  _vm._v(_vm._s(_vm.formData.productionCount)),
                ]),
                _c("info-row", { attrs: { label: "任务数量" } }, [
                  _vm._v(_vm._s(_vm.formData.taskCount)),
                ]),
                _c("info-row", { attrs: { label: "平均分" } }, [
                  _vm._v(_vm._s(_vm.formData.averageScore)),
                ]),
                _c("info-row", { attrs: { label: "等级" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.formData.level == 1
                        ? "高级"
                        : _vm.formData.level == 2
                        ? "中级"
                        : "低级"
                    )
                  ),
                ]),
                _c(
                  "info-row",
                  { attrs: { label: "备注", wrapCol: "19" } },
                  [
                    _c("a-textarea", {
                      attrs: { autoSize: "" },
                      model: {
                        value: _vm.formData.comment,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "comment", $$v)
                        },
                        expression: "formData.comment",
                      },
                    }),
                  ],
                  1
                ),
                _vm.formData.status != 4
                  ? _c(
                      "div",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _vm.formData.status > 1
                          ? _c(
                              "a-button",
                              {
                                staticClass: "mr-3",
                                attrs: { type: "danger" },
                                on: { click: _vm.rejectRating },
                              },
                              [_vm._v("驳回")]
                            )
                          : _vm._e(),
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.confirmRating },
                          },
                          [_vm._v("确认")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("g-title", [_vm._v("评分明细")]),
            _c("a-table", {
              ref: "table",
              attrs: {
                size: "middle",
                rowKey: "id",
                columns: _vm.columns,
                dataSource: _vm.taskList,
                pagination: false,
                loading: _vm.loading,
                scroll: { x: 800, y: 500 },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }